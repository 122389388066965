import { graphqlEndpoint } from '@config';

const getPath = (href = '') => {
    if (href && href !== '' && typeof window !== 'undefined') {
        const link = document.createElement('a');
        link.href = href;
        let path = link.pathname;

        // cek hostname
        const hostName = link.hostname;
        const appEnv = typeof window !== 'undefined' ? window.APP_ENV : process.env.APP_ENV;
        const env = appEnv === 'local' ? 'dev' : appEnv;
        const urlBe = graphqlEndpoint[env] || 'dev';
        const removeGraphql = urlBe.replace('/graphql', '');
        const removeHttps = removeGraphql.replace('https://', '');

        const filterOutsideLink = [removeHttps, 'https://mitra10.my.id'];

        if (path.includes('.html')) {
            path = path.split('.html');
            // eslint-disable-next-line prefer-destructuring
            path = path[0];
        }

        if (!filterOutsideLink.includes(hostName)) {
            path = href;
        }

        return path;
    }
    const appEnv = typeof window !== 'undefined' ? window.APP_ENV : process.env.APP_ENV;
    const env = appEnv === 'local' ? 'dev' : appEnv;
    let url = graphqlEndpoint[env] || 'dev';
    url = url.replace('/graphql', '');
    let path = href.replace('.html', '');
    path = path.replace(url, '');
    return path;
};

export default getPath;
